/**
 * Gutenberg Blocks
 *
 * 01. Accordion
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Accordion
     *
     * @requires plugins/jquery.accordion.js
     */
    (function () {
        $('.js-accordion').accordion({
            panelSelector: '.c-blockAccordion__item',
            titleSelector: '.c-blockAccordion__header',
            contentSelector: '.c-blockAccordion__content',
            activeClass: 'is-open'
        });
    }());

}(jQuery));
