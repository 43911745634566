/**
 * Header
 *
 * 01. Scroll Hidden/Visible
 * 02. Navigation
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Scroll Hidden/Visible
     *
     * @summary Header scroll hidden and visible
     */
    var header = $('.js-header'),
        headerFixed = 'header--fixed',
        headerVisible = 'header--visible',
        headerHeight = header.height(),
        $window = $(window);

    $window.on(
        'scroll',
        {
            previousTop: 0
        },
        function () {
            var currentTop = $window.scrollTop();

            // check if user is scrolling up
            if (currentTop < this.previousTop) {
                // if scrolling up...
                if (currentTop > 0 && header.hasClass(headerFixed)) {
                    header.addClass(headerVisible);
                } else {
                    header.removeClass(headerVisible + ' ' + headerFixed);
                }
            } else if (currentTop !== this.previousTop) {
                // if scrolling down...
                header.removeClass(headerVisible);
                if (currentTop > headerHeight && !header.hasClass(headerFixed)) {
                    header.addClass(headerFixed);
                }
            }
            this.previousTop = currentTop;
        }
    );

}(jQuery));