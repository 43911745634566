/**
 * Home
 *
 * 01. Software
 * 02. Posts
 * 03. Testimonials
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    /**
     * Software
     *
     * @requires plugins/swiper.js
     *
     * @see https://codepen.io/aaronkahlhamer/pen/GveaXP
     */
    (function () {
        new Swiper('.js-softwareCarousel', {
            speed: 1000,
            loop: true,
            slidesPerView: 1,
            preventClicks: false,
            grabCursor: true,
            watchOverflow: true,
            autoHeight: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: 10000
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            }
        });
    }());


    /**
     * Posts & Testimonials
     *
     * @type {{slidesPerView: string, grabCursor: boolean, watchOverflow: boolean, pagination: {el: string, clickable: boolean, dynamicBullets: boolean}}}
     */
    var swiperHomeConfig = {
        slidesPerView: 'auto',
        grabCursor: true,
        watchOverflow: true,
        slideClass: 'u-box',
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        }
    };


    /**
     * Posts
     *
     * @requires plugins/swiper.js
     *
     * @see https://codepen.io/aaronkahlhamer/pen/GveaXP
     */
    (function () {
        // breakpoint where swiper will be destroyed
        // and switches to a dual-column layout
        var breakpoint = window.matchMedia('(min-width: 800px)');

        // keep track of swiper instances to destroy later
        var postsCarousel = undefined;

        function breakpointChecker() {
            // if larger viewport and multi-row layout needed
            if (breakpoint.matches) {
                // clean up old instances and inline styles when available
                if (postsCarousel !== undefined) {
                    postsCarousel.destroy(false, true);
                }

                return;
            }

            // fire small viewport version of swiper
            enableSwiper();
        }

        function enableSwiper() {
            postsCarousel = new Swiper('.js-postCarousel', swiperHomeConfig);
        }

        // keep an eye on viewport size changes
        breakpoint.addListener(breakpointChecker);

        // kickstart
        breakpointChecker();
    }());


    /**
     * Testimonials
     *
     * Imposto il setTimeout perchè altrimenti la slide sbrocca nel caso del css critico.
     *
     * @requires plugins/swiper.js
     */
    (function () {
        new Swiper('.js-testimonialsCarousel', swiperHomeConfig);
    }());

}(jQuery));
